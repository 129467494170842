import { h, Component } from "preact";
import { ListGroup } from "Sgb4/ListGroup/ListGroup";
import { filter, find, map, size, take } from "lodash";
import { ListGroupItem } from "Sgb4/ListGroup/ListGroupItem";
import { ListGroupItemMessage } from "Sgb4/ListGroup/ListGroupItemMessage";
import { isStringEmpty, isStringEqual } from "Sgb4/common/strings/stringUtils";
import { ListGroupItemLoader } from "Sgb4/ListGroup/ListGroupItemLoader";
import { PickerIdName } from "../common/types/common.typings";

interface Props<T extends PickerIdName> {
  maxElementShown: number;
  showDropDown: boolean;
  isCanCreateElementFromInput?: boolean;
  isDisplayCreateLink?: boolean;
  createLinkMessage?: string;
  isLoading: boolean;
  inError: boolean;
  serverErrorMessage: string;
  selectedElements: T[];
  allElements: T[];
  searchTerm: string;
  size?: string;
  highlightedIndex: number;
  onSelect: (id: string) => void;
  onUnSelect: (id: string) => void;
  onCreateItem: (id: string, isSelected: boolean) => void;
  onRenderItem: (
    item: T,
    searchTerm: string,
    isSecondaryText: boolean
  ) => JSX.Element;
  onCreateLinkClicked?: () => void;
}

export class MultiSelectPickerDropdown<
  T extends PickerIdName
> extends Component<Props<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.renderHashtagToCreate = this.renderHashtagToCreate.bind(this);
    this.isCanCreateHashtag = this.isCanCreateHashtag.bind(this);
  }

  public render(props: Props<T>) {
    const shouldDisplayCreateLink =
      size(props.selectedElements) > 0 ||
      size(props.allElements) > 0 ||
      (!props.isLoading && !isStringEmpty(props.serverErrorMessage));
    return props.showDropDown ? (
      <ListGroup size={props.size}>
        <div
          className="content-row row no-gutters"
          style="height: 100%;max-height: 220px;overflow: auto;"
        >
          {map(
            filter(props.selectedElements, ({ filtered }) => !filtered),
            selectedItem => (
              <ListGroupItem
                id={selectedItem.id}
                label={selectedItem.name}
                selectable={true}
                selected={true}
                disabled={selectedItem.readonly}
                onSelectionChange={() => props.onUnSelect(selectedItem.id)}
                customRender={() =>
                  props.onRenderItem(selectedItem, props.searchTerm, false)
                }
              />
            )
          )}
          <ListGroupItemMessage
            isShown={
              !props.isLoading && !isStringEmpty(props.serverErrorMessage)
            }
            message={props.serverErrorMessage}
          />
          <ListGroupItemLoader isLoading={props.isLoading} />
          {map(
            take(
              filter(props.allElements, ({ filtered }) => !filtered),
              props.maxElementShown
            ),
            (element, index) => (
              <ListGroupItem
                id={element.id}
                label={element.name}
                selected={false}
                disabled={element.readonly}
                highlighted={props.highlightedIndex === index}
                customRender={() =>
                  props.onRenderItem(element, props.searchTerm, true)
                }
                onSelectionChange={() => props.onSelect(element.id)}
              />
            )
          )}
          {this.isCanCreateHashtag() && (
            <ListGroupItem
              label={props.searchTerm}
              id={props.searchTerm}
              selectable={false}
              selected={false}
              disabled={false}
              customRender={() => this.renderHashtagToCreate(props.searchTerm)}
              onSelectionChange={props.onCreateItem}
            />
          )}
        </div>
        {props.isDisplayCreateLink && shouldDisplayCreateLink && (
          <ListGroupItem
            id="create-link"
            className="text-center font-weight-medium border-top"
            label={props.createLinkMessage || "create new"}
            selectable={false}
            selected={false}
            disabled={false}
            onSelectionChange={props.onCreateLinkClicked}
          />
        )}
      </ListGroup>
    ) : null;
  }

  private isCanCreateHashtag() {
    return (
      this.props.isCanCreateElementFromInput &&
      size(this.props.searchTerm) > 2 &&
      !this.props.inError &&
      size(this.props.allElements) === 0 &&
      !find<T>(this.props.allElements, ({ name }) =>
        isStringEqual(name, this.props.searchTerm)
      )
    );
  }

  private renderHashtagToCreate(name: string) {
    return (
      <span>
        <b>{name}</b>
      </span>
    );
  }
}
