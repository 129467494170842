import { h, Component } from "preact";
import { get, isEmpty, isEqual, size } from "lodash";
import { SharedMultiSelectPicker } from "../SharedMultiSelectPicker/SharedMultiSelectPicker";
import { isStringEmpty } from "../common/strings/stringUtils";
import { getCreateContactLink } from "../../ic-sgb4-simple-contacts-picker/contactPickerLinks";
import { highlight, HighlightType } from "../common/strings/Highlight";
import { BulletValue } from "../common/BulletValue/BulletValue";
import { ErrorProps } from "../newTagPicker/common/typings";
import { ServiceParameters } from "../common/api/api.common";
import {
  SearchCriteriaCdb,
  WidgetContact,
} from "../common/types/contact.typings";
import {
  CreateLinkMode,
  DisplayOption,
  SearchCriteria,
} from "../../common/types/contact";
import { ComponentSize } from "common/types/global";

export interface SharedContactsPickerProps extends ErrorProps {
  id: string;
  serviceParams: ServiceParameters;
  placeholder: string;
  maxResultCount: number;
  selectedIds: string[];
  readonlyIds: string[];
  hostName: string;
  size?: ComponentSize;
  searchCriteria: SearchCriteriaCdb | SearchCriteria;
  displayOption: DisplayOption;
  createLinkMode: CreateLinkMode;
  onChange: (contacts: WidgetContact[]) => void;
  onCreateLinkClicked?: () => void;
  onReady?: () => void;
}

interface Props extends SharedContactsPickerProps {
  onFetchContacts: (
    selectedIds: string[],
    readonlyIds: string[]
  ) => Promise<WidgetContact[]>;
  onSearch: (searchTerm: string) => Promise<WidgetContact[]>;
}

interface State {
  contacts: WidgetContact[];
}

const ICON_CONTACT = "person";

export class SharedContactsPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { contacts: [] };
  }

  public componentDidMount() {
    this.doFetchContacts(this.props.selectedIds, this.props.readonlyIds);
  }

  public componentDidUpdate(previousProps: Readonly<Props>) {
    if (
      !isEqual(previousProps.selectedIds, this.props.selectedIds) ||
      !isEqual(previousProps.readonlyIds, this.props.readonlyIds)
    ) {
      this.doFetchContacts(this.props.selectedIds, this.props.readonlyIds);
    }
  }

  public render(props: Props, state: State) {
    const isDisplayCreateLink =
      props.createLinkMode && props.createLinkMode !== "none";
    return (
      <SharedMultiSelectPicker<WidgetContact>
        size={props.size}
        pickerId={props.id}
        iconName={ICON_CONTACT}
        maxElementShown={props.maxResultCount}
        placeholder={props.placeholder}
        hostName={props.hostName}
        errorMessage={props.errorMessage}
        inError={props.inError}
        selectedElements={state.contacts}
        isCanCreateHashtag={false}
        isDisplayCreateLink={isDisplayCreateLink}
        createLinkMessage="Create new contact"
        minCharsCountToTriggerSearch={2}
        onSearch={this.props.onSearch}
        onReady={this.props.onReady}
        onChange={this.props.onChange}
        onRenderItem={this.handleOnRenderItem}
        onShouldStopSearch={this.handleOnShouldStopSearch}
        onCreateLinkClicked={this.handleOnCreateLinkClicked}
      />
    );
  }

  private readonly handleOnShouldStopSearch = (searchTerm: string): boolean => {
    return isStringEmpty(searchTerm) || size(searchTerm) <= 2;
  };

  private readonly doFetchContacts = (
    selectedIds: string[],
    readonlyIds: string[]
  ) => {
    if (isEmpty(selectedIds) && isEmpty(readonlyIds)) {
      this.setState({ contacts: [] });
    } else {
      this.props
        .onFetchContacts(selectedIds, readonlyIds)
        .then(contacts => this.setState({ contacts }));
    }
  };

  private readonly handleOnCreateLinkClicked = () => {
    if (!this.props.createLinkMode || this.props.createLinkMode === "none") {
      return;
    }
    if (
      this.props.createLinkMode === "emit" &&
      this.props.onCreateLinkClicked
    ) {
      this.props.onCreateLinkClicked();
    }
    if (this.props.createLinkMode === "redirect") {
      window.location.assign(
        getCreateContactLink(this.props.serviceParams.environment)
      );
    }
  };

  private readonly handleOnRenderItem = (
    contact: WidgetContact,
    searchTerm: string,
    isSecondaryText = false
  ): JSX.Element => {
    const accountName = get(contact, "employeeOf.name");
    const accountLevel = get(contact, "employeeOf.bdrLegal.level");
    const email =
      contact.emails && !isEmpty(contact.emails)
        ? contact.emails[0].value
        : null;
    return (
      <span>
        {highlight(searchTerm, contact.fullName, HighlightType.Bold)}
        <span className={`ml-1 ${isSecondaryText ? "text-secondary" : ""}`}>
          {accountName && (
            <BulletValue>
              {accountName} {accountLevel ? `(${accountLevel})` : ""}
            </BulletValue>
          )}
          {this.props.displayOption === "email" && email && (
            <BulletValue>
              {highlight(searchTerm, email, HighlightType.Bold)}
            </BulletValue>
          )}
          {this.props.displayOption === "jobtitle" && contact.jobTitle && (
            <BulletValue>
              {highlight(searchTerm, contact.jobTitle, HighlightType.Bold)}
            </BulletValue>
          )}
        </span>
      </span>
    );
  };
}

export default SharedContactsPicker;
