import { h, Component } from "preact";
import SharedContactsPicker, {
  SharedContactsPickerProps,
} from "../Sgb4/SharedContactsPicker/SharedContactsPicker";
import {
  SearchCriteriaCdb,
  WidgetContact,
} from "../Sgb4/common/types/contact.typings";
import { fetchContacts, fetchContactsByTerm } from "./fetchContacts";

export interface Props extends SharedContactsPickerProps {
  searchCriteria: SearchCriteriaCdb;
}

export class ContactsPicker extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  public render({
    createLinkMode,
    displayOption,
    errorMessage,
    errorType,
    hostName,
    id,
    inError,
    maxResultCount,
    onChange,
    onCreateLinkClicked,
    onReady,
    placeholder,
    readonlyIds,
    searchCriteria,
    selectedIds,
    serviceParams,
    size,
  }: Props) {
    return (
      <SharedContactsPicker
        id={id}
        size={size}
        serviceParams={serviceParams}
        maxResultCount={maxResultCount}
        selectedIds={selectedIds}
        createLinkMode={createLinkMode}
        displayOption={displayOption}
        hostName={hostName}
        placeholder={placeholder}
        readonlyIds={readonlyIds}
        searchCriteria={searchCriteria}
        errorMessage={errorMessage}
        errorType={errorType}
        inError={inError}
        onChange={onChange}
        onReady={onReady}
        onCreateLinkClicked={onCreateLinkClicked}
        onFetchContacts={this.handleOnFetchContacts}
        onSearch={this.handleOnSearch}
      />
    );
  }

  private readonly handleOnFetchContacts = (
    selectedIds: string[],
    readonlyIds: string[]
  ): Promise<WidgetContact[]> => {
    return fetchContacts(
      this.props.serviceParams,
      selectedIds,
      readonlyIds
    ).then((selected: any) => selected);
  };

  private readonly handleOnSearch = (
    searchTerm: string
  ): Promise<WidgetContact[]> => {
    return fetchContactsByTerm(
      searchTerm,
      {
        serviceParams: this.props.serviceParams,
        maxResultCount: this.props.maxResultCount,
      },
      this.props.searchCriteria
    );
  };
}
