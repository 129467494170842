import {
  isContainSubString,
  isStringEmpty,
  isStringEqual,
} from "../common/strings/stringUtils";
import { filter, find, map, size, uniqBy, trim } from "lodash";
import { State } from "./SharedMultiSelectPicker";
import { PickerIdName } from "../common/types/common.typings";

export const onHighlightElement = <T extends PickerIdName>(
  state: State<T>,
  maxElement: number,
  isHighlightNext: boolean
): State<T> => {
  const elementLength = size(state.allElements);
  if (elementLength === 0) {
    return state;
  }
  const limit = elementLength < maxElement ? elementLength - 1 : maxElement - 1;
  const highlightedIndex = isHighlightNext
    ? state.highlightedIndex >= limit
      ? 0
      : state.highlightedIndex + 1
    : state.highlightedIndex === 0
    ? limit
    : state.highlightedIndex - 1;
  return { ...state, highlightedIndex };
};

export const onEnter = <T extends PickerIdName>(
  state: State<T>,
  minCharsCountToTriggerSearch: number,
  isCanCreate?: boolean
): State<T> => {
  if (size(state.searchTerm) > minCharsCountToTriggerSearch && !state.inError) {
    // select with cursor
    const notFilteredElements = filter(
      state.allElements,
      ({ filtered }) => !filtered
    );
    if (state.highlightedIndex > -1) {
      return state.highlightedIndex < size(notFilteredElements)
        ? onSelectElement(state, notFilteredElements[state.highlightedIndex].id)
        : state;
    }
    // to create or select
    const isElementExist = find<T>(notFilteredElements, ({ name }) =>
      isStringEqual(name, state.searchTerm)
    );
    if (isElementExist) {
      return onSelectElement(state, isElementExist.id);
    } else if (isCanCreate) {
      const isElementSelected = find<T>(state.selectedElements, ({ name }) =>
        isStringEqual(name, state.searchTerm)
      );
      return isElementSelected
        ? onSelectElement(state, isElementSelected.id)
        : onCreateElement(state, state.searchTerm);
    }
  }
  return state;
};

export const onUpdateSelectedElement = <T extends PickerIdName>(
  state: State<T>,
  elementToSelect: T[],
  minCharsCountToTriggerSearch: number
): State<T> => {
  const selectedElements = map<T, T>(elementToSelect, element => ({
    ...element,
    filtered:
      size(state.searchTerm) > minCharsCountToTriggerSearch
        ? !isContainSubString(element.name, state.searchTerm)
        : false,
  }));
  return { ...state, selectedElements };
};

export const onSearchCancel = <T extends PickerIdName>(
  state: State<T>,
  searchTerm: string,
  minCharsCountToTriggerSearch: number
): State<T> => {
  return {
    ...state,
    searchTerm,
    allElements: [],
    serverErrorMessage: "",
    highlightedIndex: -1,
    inError:
      !isStringEmpty(searchTerm) &&
      size(searchTerm) > minCharsCountToTriggerSearch,
    selectedElements: resetSelectedElements(state.selectedElements),
  };
};
export const onSearchInit = <T extends PickerIdName>(
  state: State<T>,
  searchTerm: string
): State<T> => {
  const selected = map<T, T>(state.selectedElements, element => ({
    ...element,
    filtered: !isContainSubString(element.name, searchTerm),
  }));
  return {
    ...state,
    searchTerm,
    highlightedIndex: -1,
    isLoading: true,
    inError: false,
    serverErrorMessage: "",
    selectedElements: selected,
    allElements: [],
  };
};

export const onSearchDone = <T extends PickerIdName>(
  state: State<T>,
  newElement: T[],
  minCharsCountToTriggerSearch: number,
  isCanCreate?: boolean
): State<T> => ({
  ...state,
  allElements: mapToPickerIdName(newElement, state.selectedElements),
  isLoading: false,
  inError: false,
  highlightedIndex: -1, // don't highlight the first element
  serverErrorMessage:
    size(newElement) === 0 &&
    !isCanCreate &&
    size(trim(state.searchTerm)) > minCharsCountToTriggerSearch
      ? "No results"
      : "",
});

export const onSearchFailed = <T extends PickerIdName>(
  state: State<T>
): State<T> => ({
  ...state,
  allElements: [],
  isLoading: false,
  inError: true,
  serverErrorMessage: "Search request failed (server error).",
});

export const onSelectElement = <T extends PickerIdName>(
  state: State<T>,
  id: string
): State<T> => {
  const element = find<T>(state.allElements, el => isStringEqual(el.id, id));
  if (element) {
    const selectedElements = uniqBy(
      map([...state.selectedElements, element], item => ({
        ...item,
        filtered: false,
      })),
      "id"
    );
    return {
      ...state,
      selectedElements,
      allElements: [],
      searchTerm: "",
      inError: false,
      highlightedIndex: -1,
      isDropDownShown: false,
    };
  }
  return state;
};

export const onDeSelectElement = <T extends PickerIdName>(
  state: State<T>,
  id: string
): State<T> => {
  const element = find<T>(state.allElements, el => isStringEqual(el.id, id));
  const selectedElements = filter(
    state.selectedElements,
    el => !isStringEqual(el.id, id)
  );
  const allElements = element
    ? map(state.allElements, el => ({
        ...el,
        filtered: isStringEqual(el.id, id) ? false : el.filtered,
      }))
    : [...state.allElements];
  return {
    ...state,
    selectedElements,
    allElements,
    isDropDownShown: true,
    highlightedIndex: -1,
  };
};

export const onCreateElement = <T extends PickerIdName>(
  state: State<T>,
  name: string
): State<T> => {
  const elementToAdd = {
    id: name,
    name,
    filtered: false,
    readonly: false,
  } as T;
  return {
    ...state,
    selectedElements: [
      ...resetSelectedElements(state.selectedElements),
      elementToAdd,
    ],
    searchTerm: "",
    inError: false,
    allElements: [],
    highlightedIndex: -1,
  };
};

export const onDeselectAllElement = <T extends PickerIdName>(
  state: State<T>
): State<T> => ({
  ...state,
  selectedElements: keepOnlyReadOnlyElements(state.selectedElements),
  allElements: [],
  highlightedIndex: -1,
});

export const onToggleDropdown = <T extends PickerIdName>(
  state: State<T>,
  isDropDownShown: boolean
): State<T> =>
  isDropDownShown
    ? { ...state, isDropDownShown }
    : {
        ...state,
        selectedElements: resetSelectedElements(state.selectedElements),
        searchTerm: "",
        inError: false,
        allElements: [],
        isDropDownShown,
        highlightedIndex: -1,
        isLoading: false,
        serverErrorMessage: "",
      };

const keepOnlyReadOnlyElements = <T extends PickerIdName>(elements: T[]): T[] =>
  map(
    filter(elements, element => element.readonly),
    element => ({
      ...element,
      filtered: false,
    })
  );

const resetSelectedElements = <T extends PickerIdName>(
  selectedElements: T[]
): T[] => map(selectedElements, element => ({ ...element, filtered: false }));

const mapToPickerIdName = <T extends PickerIdName>(
  allElements: T[],
  selectedElements: T[]
): T[] =>
  map<T, T>(allElements, element => ({
    ...element,
    filtered: !!find<T>(selectedElements, selectedElement =>
      isStringEqual(selectedElement.id, element.id)
    ),
  }));
