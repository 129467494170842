import { h, Component } from "preact";
import { InputClearButton } from "Sgb4/Input/InputClearbutton";
import { InvalidFeedback } from "Sgb4/common/Components/InvalidFeedback";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import * as stylesheet from "./MultiSelectPickerInput.less";
import { fromStyleLocals } from "../../common/css";
import { isStringEmpty } from "Sgb4/common/strings/stringUtils";
import { ComponentSize } from "common/types/global";

const css = fromStyleLocals(stylesheet.locals);

interface Props extends ErrorProps {
  iconName: string;
  selectedCount: number;
  children?: any;
  mergedComponent?: JSX.Element;
  placeholder: string;
  searchTerm: string;
  size?: ComponentSize;
  onClear: () => void;
  onInput: (event: any) => void;
  onFocus: () => void;
  onInputRef: (inputRef: any) => void;
  handleOnKeyUp: (event: KeyboardEvent) => void;
}

interface State {
  hasFocus: boolean;
}

export class MultiSelectPickerInput extends Component<Props, State> {
  private input: any;

  constructor(props: Props) {
    super(props);
    this.handleInputRef = this.handleInputRef.bind(this);
    this.handleOnLostFocus = this.handleOnLostFocus.bind(this);
    this.handleOnHasFocus = this.handleOnHasFocus.bind(this);
    this.state = {
      hasFocus: false,
    };
  }

  public render(props: Props, state: State) {
    const size = !isStringEmpty(props.size) ? props.size : "md";
    const inErrorStyle = props.inError
      ? css(state.hasFocus ? "invalid-focus" : "invalid")
      : "";
    const inFocusStyle = !props.inError && state.hasFocus ? css("focus") : "";
    return (
      <div>
        <style>{stylesheet.toString()}</style>
        <div
          className={`form-control form-control-${size} input-group p-0 d-flex flex-nowrap align-items-center ${inErrorStyle} ${inFocusStyle}`}
        >
          <div className="d-md-block d-none ml-2">
            <i className="icon icon-sm text-secondary">{props.iconName}</i>
          </div>
          {props.selectedCount > 0 && (
            <div
              className="selected col-auto text-primary btn-link text-info"
              onClick={this.handleOnSelectClick}
              style={{ cursor: "pointer" }}
            >
              {props.selectedCount} Selected
            </div>
          )}
          <input
            type="text"
            className={`${css(
              "input-wrap"
            )} flex-grow flex-fill border-0 bg-transparent py-0 h-100 ml-2`}
            ref={this.handleInputRef}
            value={props.searchTerm}
            placeholder={props.placeholder}
            onInput={props.onInput}
            onBlur={this.handleOnLostFocus}
            onFocus={this.handleOnHasFocus}
            onKeyUp={props.handleOnKeyUp}
          />
          {props.selectedCount > 0 && (
            <InputClearButton
              onClear={props.onClear}
              className="d-md-block d-none mr-2"
            />
          )}
          {props.mergedComponent ? props.mergedComponent : null}
          {props.children}
        </div>
        {props.inError && (
          <div>
            <InvalidFeedback errorMessage={props.errorMessage} />
          </div>
        )}
      </div>
    );
  }

  private handleOnSelectClick = () => {
    this.props.onInput({ target: { value: "" } });
    this.input.focus();
  };

  private handleInputRef(inputRef: any) {
    this.input = inputRef;
    this.props.onInputRef(inputRef);
  }

  private handleOnLostFocus() {
    this.setState({ hasFocus: false });
  }

  private handleOnHasFocus() {
    this.setState({ hasFocus: true }, () => this.props.onFocus());
  }
}
