import { h, Component } from "preact";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import { debounce, get, isEqual, size } from "lodash";
import { isStringEqual } from "Sgb4/common/strings/stringUtils";
import { MultiSelectPickerInput } from "./MultiSelectPickerInput";
import { MultiSelectPickerDropdown } from "./MultiSelectPickerDropdown";
import {
  onCreateElement,
  onDeselectAllElement,
  onDeSelectElement,
  onEnter,
  onHighlightElement,
  onSearchCancel,
  onSearchDone,
  onSearchFailed,
  onSearchInit,
  onSelectElement,
  onToggleDropdown,
  onUpdateSelectedElement,
} from "./multiSelectPicker.logic";
import { PickerIdName } from "../common/types/common.typings";
import { ComponentSize } from "common/types/global";

export interface Props<T extends PickerIdName> extends ErrorProps {
  pickerId: string;
  iconName: string;
  hostName: string;
  maxElementShown: number;
  placeholder: string;
  selectedElements: T[];
  mergedComponent?: JSX.Element;
  isCanCreateHashtag?: boolean;
  isDisplayCreateLink?: boolean;
  hideDropdownOnSelection?: boolean;
  createLinkMessage?: string;
  minCharsCountToTriggerSearch: number;
  size?: ComponentSize;
  onChange: (tags: T[]) => void;
  onShouldStopSearch: (term: string) => boolean;
  onSearch: (term: string) => Promise<T[]>;
  onReady?: () => void;
  onRenderItem: (
    item: T,
    searchTerm: string,
    isSecondaryText: boolean
  ) => JSX.Element;
  onCreateLinkClicked?: () => void;
}

export interface State<T extends PickerIdName> {
  selectedElements: T[];
  allElements: T[];
  searchTerm: string;
  isDropDownShown: boolean;
  isLoading: boolean;
  inError: boolean;
  serverErrorMessage: string;
  highlightedIndex: number;
}

export class SharedMultiSelectPicker<T extends PickerIdName> extends Component<
  Props<T>,
  State<T>
> {
  private inputRef: any;

  private debounceSearch = debounce((term: string) => {
    this.props
      .onSearch(term)
      .then(hashtags => {
        this.updateState(
          onSearchDone(
            this.state,
            hashtags,
            this.props.minCharsCountToTriggerSearch,
            this.props.isCanCreateHashtag
          )
        );
      })
      .catch(() => {
        this.updateState(onSearchFailed(this.state));
      });
  }, 300);

  constructor(props: Props<T>) {
    super(props);
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnUnSelect = this.handleOnUnSelect.bind(this);
    this.handleOpenDropdown = this.handleOpenDropdown.bind(this);
    this.handleCloseDropDown = this.handleCloseDropDown.bind(this);
    this.handleOnCreateItem = this.handleOnCreateItem.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
    this.updateState = this.updateState.bind(this);
    this.state = {
      selectedElements: [],
      allElements: [],
      searchTerm: "",
      isDropDownShown: false,
      isLoading: false,
      serverErrorMessage: "",
      inError: false,
      highlightedIndex: -1,
    };
  }

  public componentWillReceiveProps(nextProps: Readonly<Props<T>>): void {
    if (!isEqual(this.props.selectedElements, nextProps.selectedElements)) {
      this.setState(
        onUpdateSelectedElement<T>(
          this.state,
          nextProps.selectedElements,
          this.props.minCharsCountToTriggerSearch
        )
      );
    }
  }

  public componentDidMount() {
    if (this.props.onReady) {
      this.props.onReady();
    }
    document.addEventListener("click", this.handleCloseDropDown, false);
    this.setState(
      onUpdateSelectedElement(
        this.state,
        this.props.selectedElements,
        this.props.minCharsCountToTriggerSearch
      )
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleCloseDropDown);
  }

  public render(props: Props<T>, state: State<T>) {
    const isInError = get(props, "inError", false) || state.inError;
    const selectedCount = size(state.selectedElements);
    return (
      <div>
        <MultiSelectPickerInput
          iconName={props.iconName}
          searchTerm={state.searchTerm}
          onInputRef={(inputRef: any) => (this.inputRef = inputRef)}
          selectedCount={selectedCount}
          placeholder={props.placeholder}
          size={props.size}
          mergedComponent={props.mergedComponent}
          onInput={this.handleOnSearch}
          onFocus={this.handleOpenDropdown}
          handleOnKeyUp={this.handleOnKeyUp}
          onClear={this.handleClearSelection}
          inError={isInError}
          errorMessage={props.errorMessage}
        >
          <MultiSelectPickerDropdown
            maxElementShown={props.maxElementShown}
            searchTerm={state.searchTerm}
            isCanCreateElementFromInput={props.isCanCreateHashtag}
            selectedElements={state.selectedElements}
            allElements={state.allElements}
            isLoading={state.isLoading}
            inError={state.inError}
            serverErrorMessage={state.serverErrorMessage}
            showDropDown={state.isDropDownShown}
            highlightedIndex={state.highlightedIndex}
            isDisplayCreateLink={props.isDisplayCreateLink}
            createLinkMessage={props.createLinkMessage}
            size={props.size}
            onSelect={this.handleOnSelect}
            onUnSelect={this.handleOnUnSelect}
            onCreateItem={this.handleOnCreateItem}
            onRenderItem={props.onRenderItem}
            onCreateLinkClicked={props.onCreateLinkClicked}
          />
        </MultiSelectPickerInput>
      </div>
    );
  }

  private handleOnSearch(event: any) {
    const term = event.target.value;

    const stopSearch = this.props.onShouldStopSearch(term);
    if (stopSearch) {
      this.setState(
        onSearchCancel(
          this.state,
          term,
          this.props.minCharsCountToTriggerSearch
        )
      );
      return;
    }
    this.updateState(onSearchInit(this.state, term));
    this.debounceSearch(term);
  }

  private handleOnSelect(id: string) {
    this.updateState(onSelectElement(this.state, id));
  }
  private handleOnUnSelect(id: string) {
    this.updateState(onDeSelectElement(this.state, id));
  }

  private handleOnCreateItem(hashTagName: string) {
    this.updateState(onCreateElement(this.state, hashTagName));
  }

  private handleClearSelection() {
    this.updateState(onDeselectAllElement(this.state));
  }

  private handleOnKeyUp(event: KeyboardEvent) {
    if (isStringEqual(event.key, "Enter")) {
      this.updateState(
        onEnter(
          this.state,
          this.props.minCharsCountToTriggerSearch,
          this.props.isCanCreateHashtag
        )
      );
    } else if (isStringEqual(event.key, "ArrowDown")) {
      this.updateState(
        onHighlightElement(this.state, this.props.maxElementShown, true)
      );
    } else if (isStringEqual(event.key, "ArrowUp")) {
      this.updateState(
        onHighlightElement(this.state, this.props.maxElementShown, false)
      );
    }
  }

  private handleCloseDropDown(event: any): void {
    if (this.props.mergedComponent) {
      // check if the button in the merged component is clicked.
      // close the dropdown if that's the case.
      const paths = event.path;
      if (
        paths &&
        paths[0] &&
        paths[0].getAttribute("class") &&
        paths[0].getAttribute("class").includes("close-dropdown")
      ) {
        this.setState(onToggleDropdown(this.state, false));
        return;
      }
    }
    if (this.base && this.base.contains(event.target)) {
      return;
    }
    const hostName = this.props.hostName || "";
    if (
      event.target.id &&
      isStringEqual(this.props.pickerId, event.target.id)
    ) {
      return;
    } else if (
      !event.target.id &&
      isStringEqual(event.target.nodeName, hostName)
    ) {
      return;
    }
    this.setState(onToggleDropdown(this.state, false));
  }

  private handleOpenDropdown() {
    if (!this.state.isDropDownShown) {
      this.updateState(onToggleDropdown(this.state, true));
    }
  }

  private updateState(state: State<T>) {
    if (!isEqual(state.selectedElements, this.state.selectedElements)) {
      this.setState(state, () => this.handleOnChange(state.selectedElements));
    } else {
      this.setState(state);
    }
  }

  private handleOnChange(elements: T[]) {
    this.props.onChange(elements);
    if (!this.props.hideDropdownOnSelection) {
      this.inputRef.focus();
    }
  }
}
