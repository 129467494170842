import {
  h,
  SgWidget,
  initializeWidgetConfiguration,
  WidgetConfigurationProvider,
} from "@sgwt-widget/core";
import { emit, props } from "skatejs";
import WidgetTracker from "common/component/WidgetTracker/WidgetTracker";
import {
  isStringEmpty,
  convertToBoolean,
} from "Sgb4/common/strings/stringUtils";
import { initFetching } from "Sgb4/common/api/api.common";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";
import { skateJSErrorProps } from "Sgb4/newTagPicker/common/skateJs.typings";
import { mapToContactOutputDto } from "Sgb4/newTagPicker/mapperOutputDto/mapToContactOutputDto";
import { CreateLinkMode, DisplayOption } from "common/types/contact";
import { isStringEmptyOrNull } from "../ic-contact-editor/component/validator";
import { SearchCriteriaCdb } from "../Sgb4/common/types/contact.typings";
import { ContactsPicker } from "./ContactsPicker";
import WithCss from "Sgb4/common/Components/WithCss";
import { isEmpty } from "lodash";
import { ComponentSize } from "common/types/global";
import { deprecationMessage } from "../common/depricationMessage";

export interface Props extends ErrorProps {
  id: string;
  maxResultCount: number;
  placeholder: string;
  selectedIds: string[];
  readonlyIds: string[];
  userEmail: string;
  ssoV2: boolean;
  soaUrl?: string;
  size?: ComponentSize;
  sgmContactsUrl?: string;
  internalOnly?: string;
  onlyActive?: string;
  forceSgb4?: string;
  displayOption: DisplayOption;
  createLinkMode: CreateLinkMode;
  formControlSize: string;
}

export class SgWidgetSimpleContactsPicker extends SgWidget<Props> {
  public static is = "ic-sgb4-simple-contacts-picker";
  public static props = {
    ...skateJSErrorProps,
    id: props.string,
    maxResultCount: props.number,
    placeholder: props.string,
    selectedIds: props.array,
    readonlyIds: props.array,
    userEmail: props.string,
    ssoV2: props.boolean,
    size: props.string,
    sgmContactsUrl: props.string,
    internalOnly: props.string,
    displayOption: props.string,
    createLinkMode: props.string,
    onlyActive: props.string,
    forceSgb4: props.string,
  };
  public constructor() {
    super();
    deprecationMessage(SgWidgetSimpleContactsPicker.is);
  }

  public render({
    selectedIds = [],
    readonlyIds = [],
    size,
    userEmail,
    displayOption = "none",
    ssoV2,
    errorMessage,
    inError,
    maxResultCount,
    placeholder,
    id,
    sgmContactsUrl,
    internalOnly,
    createLinkMode,
    onlyActive,
    forceSgb4,
  }: Props) {
    const defaultPlaceholder = isStringEmpty(placeholder)
      ? "Search and select"
      : placeholder;
    const formattedDisplayOption = isStringEmptyOrNull(displayOption)
      ? "none"
      : displayOption;
    const searchCriteria: SearchCriteriaCdb = {
      isInternal: convertToBoolean(internalOnly),
      onlyActive: convertToBoolean(onlyActive),
    };
    const defaultMaxResultCount =
      maxResultCount && maxResultCount > 0 ? maxResultCount : 20;
    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithCss
          forceSgb4={convertToBoolean(isEmpty(forceSgb4) ? "true" : forceSgb4)}
        >
          <ContactsPicker
            id={id}
            serviceParams={initFetching(
              this.widgetConfiguration,
              undefined,
              sgmContactsUrl,
              {},
              userEmail,
              ssoV2
            )}
            placeholder={defaultPlaceholder}
            maxResultCount={defaultMaxResultCount}
            selectedIds={selectedIds}
            readonlyIds={readonlyIds}
            size={size}
            searchCriteria={searchCriteria}
            inError={inError}
            errorMessage={errorMessage}
            createLinkMode={createLinkMode}
            hostName={SgWidgetSimpleContactsPicker.is}
            onReady={this.handleOnReady}
            onChange={this.handleOnContactsSelectionChange}
            onCreateLinkClicked={this.handleOnCreateLinkClicked}
            displayOption={formattedDisplayOption}
          />
          <WidgetTracker
            widgetName={SgWidgetSimpleContactsPicker.is}
            env={this.widgetConfiguration.environment}
          />
        </WithCss>
      </WidgetConfigurationProvider>
    );
  }

  private handleOnContactsSelectionChange = (contacts: any[]) =>
    emit(
      this,
      `${SgWidgetSimpleContactsPicker.is}--changed`,
      mapToContactOutputDto(contacts)
    );

  private handleOnCreateLinkClicked = () =>
    emit(this, `${SgWidgetSimpleContactsPicker.is}--create-link-clicked`, {});

  private handleOnReady = () =>
    emit(this, `${SgWidgetSimpleContactsPicker.is}--ready`, {
      detail: {},
    });
}

initializeWidgetConfiguration(SgWidgetSimpleContactsPicker);
customElements.define(
  SgWidgetSimpleContactsPicker.is,
  SgWidgetSimpleContactsPicker
);
